import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image"
import { Formik, Field } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { updateProfile } from '../state/actions/login/loginAction';
import flag from "../images/flag.png"
import { navigate } from "gatsby"
import { getMethod } from '../helper/api';
import {notify} from 'react-notify-toast'
import { getWindowItem } from '../helper/globals'
import ReactFlagsSelect from 'react-flags-select';
import Rolldate from 'rolldate'
import { Cookies } from 'react-cookie';

const SignUp = ({ user }) => {

    const dispatch = useDispatch();
    const countryCode = {"91":"IN","1":"US","44":"GB","20":"EG"};
    //const [isUpdated, setIsUpdated] = useState(location?.state?.addUser?true:false)
    const [isUpdatedVal, setIsUpdatedVal] = useState(1)
    const [ArabicSelect,setArabicSelect] = useState(user?.authenticate?.user?.known_languages?.indexOf('Arabic')>=0?{checked:"checked"}:{});
    const [EnglishSelect, setEnglishSelect] = useState(user?.authenticate?.user?.known_languages?.indexOf('English')>=0?{checked:"checked"}:{});
    const [thumb, setThumb] = useState(user?.authenticate?.user?.photo!==''?user?.authenticate?.user?.photo:'');

    let cookies = new Cookies();
    
    //const [country, setCountry] = useState('IN');
    let country = '';


    const LoginDetails = useSelector(state => state.loginReducer.authenticate);
    let userFirebase_id = '';
    let userId = '';
    if (LoginDetails && LoginDetails.firebase_id) {
        userFirebase_id = LoginDetails.firebase_id;
        userId = LoginDetails.idst;
    }

    if (user.authenticate.user && user.authenticate.user.firstname) {
        if(user.authenticate.user.user_role === "student" ){
            navigate('/dashboard/?v='+window.localStorage.getItem('appVersion'));
        }else{
            navigate('/preferences/?v='+window.localStorage.getItem('appVersion'));
        }
        
    }

    if (user.authenticate.user) {
        if (user.authenticate.user.email && user.authenticate.user.email.indexOf('firebase') >= 0) {
            user.authenticate.user.email = '';
        }
    } else {
        user.authenticate.user = {};
    }

    //console.log(user.authenticate.user);

    useEffect(() => {
        async function getUser() {
            let resp = getMethod(`/users/me`);
            //console.log(resp.data);
        }
        getUser();
        //console.log("comes here");

        new Rolldate({
            el: '#date_of_birth',
            format: 'DD-MM-YYYY',
            beginYear: 1950,
            endYear: moment().add(-3, 'years').format('yyyy'),
            value: user.authenticate.user.date_of_birth?moment(user.authenticate.user.date_of_birth,'yyyy-MM-DD').format('DD-MM-yyyy'):moment().add(-3, 'years').format('yyyy-MM-DD'),
            lang: { 
                title: 'Select A Date', 
                cancel: 'Cancel', 
                confirm: 'Confirm', 
                year: '', 
                month: '', 
                day: '', 
                hour: '', 
                min: '', 
                sec: '' 
              },
              confirm: function(date) {
                user.authenticate.user.date_of_birth = date;
                //setIsUpdatedVal(isUpdatedVal+1);
                var event = new Event('change');
                document.getElementById('date_of_birth').dispatchEvent(event);
                setIsUpdatedVal(isUpdatedVal+1);
              },
        })
    }, [])

    //console.log(user.authenticate.user);
    
    // if(user.authenticate.user!==undefined && user.authenticate.user.date_of_birth!==undefined){
    //     user.authenticate.user.date_of_birth = new Date(user.authenticate.user.date_of_birth);
	// //dob = date_of_birth;
    // }

    user.authenticate.user.user_role = getWindowItem('role',false)?getWindowItem('role',false):cookies.get('role');

    let phone = user.authenticate?.user?.phone?user.authenticate?.user?.phone:'';

    if(phone){
        country = countryCode[phone.substr(0,1)==='1'?phone.substr(0,1):phone.substr(0,2)];
    }

    // let dob = "";
    // if(user.authenticate.user!==undefined && user.authenticate.user.date_of_birth!==undefined && user.authenticate.user.date_of_birth!== null){
    //     user.authenticate.user.date_of_birth = new Date(user.authenticate.user.date_of_birth);
    // }

    //console.log("Country:", phone,'-', country);

    async function photoUploaded(file){
        //console.log(file);
        let reader = new FileReader();

        reader.onloadend = () => {
            //console.log("loaded");
            //console.log(reader.result);
            setThumb(reader.result);
            setIsUpdatedVal(isUpdatedVal+1);
        };

        reader.readAsDataURL(file);
    }



    return isUpdatedVal && (<div>
        <Formik
            initialValues={user.authenticate.user}
	    
            validate={values => {
                values.date_of_birth=user.authenticate.user.date_of_birth;
                //console.log(values);
                const errors = {};
                if (!values.firstname) {
                    errors.firstname = 'Required';
                }
                 if (!values.lastname) {
                    errors.lastname = 'Required';
                }
                 if (!values.phone) {
                    errors.phone = 'Required';
                }
                 if (!values.email) {
                    errors.email = 'Required';
                }
                 if (!values.gender) {
                    errors.gender = 'Required';
                }
                 if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Please enter valid email address';
                }

                //console.log(values.date_of_birth);

                // if (!values.date_of_birth || values.date_of_birth==='Invalid Date') {
                    
                //     errors.date_of_birth = 'Required';

                // }

                // if (new Date(values.date_of_birth).getTime() > new Date().getTime()) {
                    
                //     errors.date_of_birth = 'Please enter correct date';
                // }else if (new Date(values.date_of_birth).getTime() > (new Date().getTime()-(1000*60*60*24*365*5))) {
                    
                //     errors.date_of_birth = 'Minimum age should be 5 years';
                // }
                
                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                
		        delete values['wallet'];
                //const updatedValues = { ...values, date_of_birth: moment(values.date_of_birth,'DD-MM-yyyy').format('yyyy-MM-DD'), firebase_id: userFirebase_id, idst: userId }
                if(!Array.isArray(values.known_languages)){
                    values.known_languages = values.known_languages?.split(',');
                }

                if(!values.date_of_birth){
                    values.date_of_birth = moment().add(user.authenticate.user.user_role=='student'?-3:-15, 'years').format('DD-MM-yyyy').toString();
                }

                //console.log(values);
                
                const updatedValues = { ...values, date_of_birth: moment(values.date_of_birth,'DD-MM-yyyy').format('yyyy-MM-DD'), firebase_id: userFirebase_id, idst: userId,  photo: thumb, known_languages: values.known_languages?.join(',')}
                //console.log(updatedValues)
                setSubmitting(true);
                await dispatch(updateProfile(updatedValues, user.authenticate.user.id));
                //setTimeout(() => notify.show("Profile Saved Successfully", "success"), 100);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
            }) => (
                <Layout title="Profile">
                    <Seo title="Home" />
                    <div className="container">

                        <div className="row">
                        </div>
                    </div>
                    <div className="signup-section">
                        <div className="container">
                            <form onSubmit={handleSubmit}>
                                <input type="hidden" name="user_role" value={values.user_role} />
                                {thumb!=='' && <input type="hidden" name="photo" value={thumb} />}
                                
                                <div className="profile-avatar rounded-circle mx-auto" onClick={()=>{document.getElementById('photofile').click();}}>
                                    <i className="icon-Profile icon"/>
                                    {thumb && <div className="avatar" style={{backgroundImage:`url(${thumb})`}} /> }
                                </div>

                                <input id="photofile" name="file" type="file" onChange={(event) => {
                                        photoUploaded(event.currentTarget.files[0]);
                                    }} style={{display:'none'}} className="form-control" />
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <i className="icon icon-User" />
                                    </div>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="firstname"
                                        tabIndex={1}
                                        onChange={handleChange}
                                        value={values.firstname}
                                        id="first-name"
                                        placeholder="First Name *"
                                        maxLength={80}
                                    />
                                    {errors.firstname && touched.firstname && errors.firstname}
                                </div>
                                
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <i className="icon icon-User" />
                                    </div>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="last-name"
                                        tabIndex={2}
                                        name="lastname"
                                        placeholder="Last Name *"
                                        maxLength={80}
                                        onChange={handleChange}
                                        value={values.lastname}
                                    />
                                    {errors.lastname && touched.lastname && errors.lastname}
                                </div>

                                <div className="input-group with-dropdown">
                                    {/* <a type="button" className="input-group-text">
                                        <img src={flag} alt="flag" className="img-fluid" />
                                    </a> */}
                                    <ReactFlagsSelect
                                        countries={["IN", "US", "GB","EG"]}
                                        selected={country}
                                        disabled="disabled"
                                        />
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="phone"
                                        tabIndex={3}
                                        name="phone"
                                        placeholder="Phone Number *"
                                        maxLength={80}
                                        onChange={handleChange}
                                        value={values.phone}
                                        readOnly="readOnly"

                                    />
                                    {errors.phone && touched.phone && errors.phone}
                                </div>

                                
                                    
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <i className="icon icon-Email" />
                                    </div>
                                    {errors.email && <div class="invalid-tooltip d-block">{errors.email && touched.email && errors.email}</div>}
                                    <input
                                        className="form-control"
                                        type="email"
                                        id="email"
                                        tabIndex={4}
                                        name="email"
                                        placeholder="Email id *"
                                        maxLength={80}
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                </div>
                                    
                                <label>Gender<sup className="mandatory-field">*</sup></label>
                                <div className="row gx-3" role="group" aria-labelledby="my-radio-group" style={{width:"100%"}}>
                                    <div className="col">
                                        <Field
                                            type="button"
                                            id="male"
                                            tabIndex={5}
                                            name="gender"
                                            className={values.gender == 'Male' ? "btn btn-primary w-100" : "btn btn-default text-dark w-100"}
                                            onClick={handleChange}
                                            value="Male"
                                        />
                                    </div>  
                                    <div className="col">  
                                        <Field
                                            type="button"
                                            id="female"
                                            tabIndex={6}
                                            name="gender"
                                            className={values.gender == 'Female' ? "btn btn-primary w-100" : "btn btn-default text-dark w-100"}
                                            onClick={handleChange}
                                            value="Female"
                                        />
                                    </div>    
                                    {errors.gender && touched.gender && errors.gender}
                                </div>

                                {values.user_role==='faculty' && <div className="input-group">
                                    <div className="input-group-text">
                                        <i className="icon icon-Class" />
                                    </div>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="class-link"
                                        tabIndex={7}
                                        name="class_link"
                                        placeholder="Input Google Meet - Link"
                                        maxLength={80}
                                        onChange={handleChange}
                                        value={values.class_link}
                                    />
                                </div>}
                                {values.user_role==='faculty' && 
                                <><label>
                                Known Languages
                                </label>
                                <div className="input-group">
                                    {/* <div className="input-group-text">
                                        <i className="icon icon-Class" />
                                    </div> */}
                                    
                                    <div role="group" aria-labelledby="checkbox-group" className="form-control">
                                        
                                        
                                        <label style={{paddingLeft:'25px'}}>
                                        <Field type="checkbox" name="known_languages" value="Arabic" {...ArabicSelect} onClick={()=>setArabicSelect({})}/>
                                        &nbsp; Arabic
                                        </label>
                                       
                                        <label style={{paddingLeft:'25px'}}>
                                        <Field type="checkbox" name="known_languages" value="English" {...EnglishSelect} onClick={()=>setEnglishSelect({})}/>
                                         &nbsp; English
                                        </label>
                                    </div>
                                    
                                </div></>}

                                {values.user_role==='faculty' && 
                                <>
                                <label>Experience</label>
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <i className="icon icon-Class" />
                                    </div>
                                    {/* <input
                                        className="form-control"
                                        type="text"
                                        id="experience"
                                        tabIndex={7}
                                        name="experience"
                                        placeholder="Experience"
                                        maxLength={80}
                                        onChange={handleChange}
                                        value={values.experience}
                                    /> */}
                                    
                                    <select
                                        name="experience"
                                        value={values.experience}
                                        onChange={handleChange}
                                        style={{ display: 'block' }}
                                        className="form-control"
                                    >
                                        <option value="" label="--Select--" >--Select--</option>
                                        <option value="UG" label="UG" >UG</option>
                                        <option value="PG" label="PG" >PG</option>
                                        <option value="Diploma" label="Diploma" >Diploma</option>
                                    </select>
                                </div></>}

                                {values.user_role==='faculty' && <div className="input-group">
                                    <div className="input-group-text">
                                        <i className="icon icon-Class" />
                                    </div>
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        id="detail_summary"
                                        tabIndex={7}
                                        name="detail_summary"
                                        placeholder="Tell us about your education"
                                        maxLength={80}
                                        onChange={handleChange}
                                    >{values.detail_summary}
                                        </textarea>
                                </div>}
                                <label>Date of Birth<sup className="mandatory-field">*</sup></label>
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <i className="icon icon-Calendar" />
                                    </div>
                                    {/* <DatePicker
                                        selected={values.date_of_birth}
                                        dateFormat="MMMM d, yyyy"
                                        className="form-control"
                                        placeholderText="DD/MM/YYYY"
                                        name="date_of_birth"
                                        onChange={date => setFieldValue('date_of_birth', date)}
                                        showYearDropdown
                                        showMonthDropdown
                                        scrollableYearDropdown
                                        scrollableMonthDropdown
                                        yearDropdownItemNumber={80}
                                        maxDate={new Date()}
		    			tabIndex={7}
                                    /> */}
                                    {/* <input 
                                        type="date" 
                                        id="date_of_birth" 
                                        tabIndex={7} 
                                        name="date_of_birth" 
                                        placeholder="DD/MM/YYYY" 
                                        maxLength={80}  
                                        onChange={handleChange}
                                        value={values.date_of_birth} 
                                        onClick={()=>{return "false";}}
		    			                className="form-control"
                                        /> */}
                                        <input readonly 
                                        type="text" 
                                        name="date_of_birth" 
                                        id="date_of_birth" 
                                        className="form-control" 
                                        //value={values.date_of_birth?moment(values.date_of_birth,'DD-MM-yyyy').format('DD-MM-yyyy'):""}
                                        placeholder=""
                                        onClick={(e)=>{e.target.focus()}}
                                        onChange={handleChange}></input>
                                        <div></div>
                                    <div className="input-icon float-right b-none">
                                        <i className="icon icon-Down" />
                                    </div>
                                    <br/>{errors.date_of_birth && touched.date_of_birth && errors.date_of_birth}
                                </div>
                                <div class="btn-wrapper">
                                    <button className="btn btn-primary w-100" type="submit">
                                        Update
                                    </button>
                                </div>    
                            </form>
                        </div>
                    </div>            
                </Layout>
            )}
        </Formik>
    </div>)
}

export default connect(state => ({ user: state.loginReducer }), null)(SignUp);
